<template>
  <div>
    <app-navbar />
    <v-main>
      <br><br><br>
      <v-row justify="center">
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-icon size="210">
            mdi-shield-off-outline
          </v-icon>
          <br><br><br>
          <h1 class="text-center grey--text text--darken-1">
            <span class="red--text text--darken-2">Error 403 Forbbiden:</span> <br>  Esta pagina tiene acceso restringido
          </h1>
          <v-col
            cols="12"
            class="text-center mt-3"
          >
            <router-link
              to="/"
              class="grey--text"
              :style="{ textDecoration: 'none' }"
            >
              Home
            </router-link>
          </v-col>
        </v-col>
      </v-row>
      <br><br><br>
      <app-footer simple />
    </v-main>
  </div>
</template>

<script>
  import AppNavbar from '@/components/Navbar.vue'
  import AppFooter from '@/components/Footer.vue'
  export default {
    name: 'Forbidden',

    components: {
      AppNavbar,
      AppFooter,

    },

    data () {
      return {
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
      }
    },

    methods: {
      save (e) {
        e.preventDefault()
        // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.form)
      },
    },

  }
</script>
